import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  minScore: number;
  calculateAccumulatedPercentage: () => string;
  maxScore: number;
  selectedPercentages: any;
  calculateProgress: () => number;
  getProgressBarColor: (progress: number) => string;
}

const PercentageMeter: React.FC<Props> = ({
  minScore,
  calculateAccumulatedPercentage,
  maxScore,
  calculateProgress,
}) => {
  const { t } = useTranslation();
  const progress = calculateProgress();
  const accumulatedPercentage = calculateAccumulatedPercentage();

  return (
    <div className="text-center mt-6 relative">
      <div className="flex justify-between w-full">
        <div className="flex flex-col justify-start items-start">
          <h2 className="text-lg font-bold">
            {t("Percentages.min-sentence")}: {minScore.toFixed(2) + "%"}
          </h2>
          <h3 className="text-base font-medium">
            {t("Percentages.min-subsentence")}
          </h3>
        </div>

        <h2 className="text-lg font-bold">
          {t("Percentages.accumulated")}: {accumulatedPercentage}
        </h2>

        <div className="flex flex-col justify-start items-start">
          <h2 className="text-lg font-bold">
            {t("Percentages.max-sentence")}: {maxScore.toFixed(2) + "%"}
          </h2>
          <h3 className="text-base font-medium">
            {t("Percentages.max-subsentence")}
          </h3>
        </div>
      </div>

      {/* Progress Bar */}
      <div className="relative bg-gray-200 h-8 rounded-full mt-4">
        <div
          className="h-full rounded-full transition-all duration-300"
          style={{
            width: `100%`,
            background:
            "linear-gradient(to right, red 0%, orange 8%, yellow 20%, green 40%, green 60%, yellow 80%, orange 92%, red 100%)",
          }}
        ></div>

        {/* Arrow Pointer */}
        <div
          className="absolute pointer-arrow-container"
          style={{ left: `calc(${progress}% - 15px)` }}
        >
          <div className="pointer-arrow"></div>
        </div>

        {/* Centered Percentage Text */}
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-black font-semibold">
            {/* {accumulatedPercentage}% */}
          </span>
        </div>
      </div>

      {/* Custom CSS for the Arrow Pointer */}
      <style >{`
        .pointer-arrow-container {
          top: 30px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 10;
        }
        .pointer-arrow {
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 20px solid #ff5722;
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
          animation: bounce 2s infinite;
        }
        @keyframes bounce {
          0%,
          100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-5px);
          }
        }
      `}</style>
    </div>
  );
};

export default PercentageMeter;
