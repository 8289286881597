import { useContext, useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext"; // Update with your AuthContext path

interface Props {
  children: any;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const { user ,login} = useAuth(); // Get user and userBalance from context

  const storedUser = localStorage.getItem("user");
  if(storedUser) {
    // login(JSON?.parse(storedUser))
  }
console.log('user',user)
console.log('storedUser',storedUser)

  // If the user is not logged in, redirect to the login page
  if (!user && !storedUser) {
    return <Navigate to="/login" />;
  }

  // If the user is authenticated and has sufficient tokens, render the children
  return children;
};

export default ProtectedRoute;
